import _ from 'lodash'
import { Cookies } from 'react-cookie'
import apiService from '@dishopsaas/dishop-backend-api-service'
import { listenFirebaseUsersData, unlistenFirebase } from '../../api/firebase/firebaseOn'
import { sendGoogleAnalytics } from '../../api/google'
import {
  EMAIL,
  GET_SIGNUP_DATA,
  RESET_LOGIN,
  RESET_ORDER,
  RESET_PASSWORD_ERROR,
  SHOW_CONNEXION_MODAL,
  SHOW_FORGET_PASSWORD,
  SHOW_SIGNUP,
  UPDATE_PROFILE,
  USER_CONNECTION,
  USER_CONNECTION_ERROR,
  USER_SIGNOUT,
  USER_SIGNUP_ERROR,
  USER_START_CONNECTION,
  USER_START_RESET_PASSWORD,
} from './types';
import { AUTH_TYPES, ORDER_TYPE_CLICK_AND_SEAT } from '../../constants';
import { showSignUp, showSplash } from './componentAction';
import {
  createCookieCustomer,
  getCookieCustomerId,
  getWebViewCustomerId,
  resetCookieCustomerId,
} from '../../utils/customer';
import { getShopIdFromLink } from '../../utils/shop';
import { sendCloudWatchAlert, sendCloudWatchLogs } from '../../utils/logs';
import { isQrCode, isWebView } from '../../utils/config';
import {
  forgetPasswordUser,
  getFirebaseCustomerId,
  getIdToken,
  signInUser,
  signOut,
  signUpUser,
} from '../../api/firebase/firebaseAuthentication';
import {
  createCustomerBackend,
  deleteCustomerAccountBackend,
  getCustomerBackend,
} from '../../api/backend';
import { getFirebaseAuth } from '../../api/firebase/firebaseInit';
import { addAddress, updateAddress } from './pendingOrderAction';
import { getCustomerAddress } from '../../utils/customer-address-and-shops';
import { dispatchDataFromCookies } from '../../utils';
import store from '../store';
import { GoogleAuthProvider, FacebookAuthProvider, signInWithPopup, onAuthStateChanged } from 'firebase/auth';
import { analyticsEvents, PAGES, PROVENANCE, PAGE_TYPES } from '../../utils/firebaseAnalytics'

export const userConnection =
  (email, password) => async (dispatch, getState) => {
    try {
      sendCloudWatchLogs(`User ${email} connecting`);
      dispatch({ type: USER_START_CONNECTION });
      const customerId = await signInUser(email, password);
      await userConnectionSuccess(dispatch, getState, customerId, false);
      sendGoogleAnalytics('login', { method: 'firebase' });
      analyticsEvents.logLogin(AUTH_TYPES.INTERNAL)
    } catch (error) {
      const errorMessage = String(error);
      if (
        !errorMessage.includes('auth/too-many-requests') &&
        !errorMessage.includes('auth/wrong-password') &&
        !errorMessage.includes('auth/user-not-found') &&
        !errorMessage.includes('auth/account-exists-with-different-credential')
      ) {
        sendCloudWatchAlert(`Could not login: ${errorMessage}`);
      }
      dispatch({ type: USER_CONNECTION_ERROR, payload: errorMessage });
    }
  };
export const facebookOrGoogleSignUp =
  (profile, loginMethod) => async (dispatch, getState) => {
    try {
      sendCloudWatchLogs(`Creating new account`);
      await createCustomerBackend(profile);
      facebookOrGoogleLogin(dispatch, getState, loginMethod);
    } catch (error) {
      dispatch({
        type: USER_CONNECTION_ERROR,
        payload: `Nous n'avons pas pu vous connecter via ${loginMethod}! Veuillez réessayer`,
      });
    }
  };

export const facebookOrGoogleLogin = (dispatch, getState, loginMethod) => {
  sendCloudWatchLogs(`User connecting with ${loginMethod}`);
  dispatch({ type: USER_START_CONNECTION });
  const customerId = getFirebaseCustomerId();
  userConnectionSuccess(dispatch, getState, customerId, false);
  sendGoogleAnalytics('login', { method: loginMethod });
  analyticsEvents.logLogin(loginMethod)
}

export const facebookOrGoogleSignIn = (loginMethod) => async (dispatch, getState) => {
  try {
    const provider = loginMethod === AUTH_TYPES.GOOGLE ? new GoogleAuthProvider() : new FacebookAuthProvider();
    provider.addScope('email');
    const auth = getFirebaseAuth();
    auth.languageCode = 'fr_FR';
    const response = await signInWithPopup(auth, provider);
    const { user } = response;
    const email = user?.providerData[0]?.email;
    const isNewUser = user?.metadata?.creationTime === user?.metadata?.lastSignInTime;
    const { uid, displayName } = response.user;
    const firstName = displayName.split(' ')[0];
    const lastName = displayName.split(/ (.+)/)[1];
    if (isNewUser || !(await apiService.userExists(uid))) {
      await dispatch(getSignUpData({ firstName, lastName, email, uid, loginMethod }));
      dispatch(showSignUp(true));
      analyticsEvents.logSignUp(loginMethod);
      analyticsEvents.logPageViewEvent(PAGES.SIGN_UP, PROVENANCE.LOGIN_PAGE, PAGE_TYPES.EXTERNAL);
    } else {
      facebookOrGoogleLogin(dispatch, getState, loginMethod)
    }
  } catch (error) {
    const errorMessage = String(error);
    if (
      !errorMessage.includes('auth/popup-closed-by-user')
      && !errorMessage.includes('The popup has been closed by the user')
      && !errorMessage.includes('auth/cancelled-popup-request')
      && !errorMessage.includes('auth/user-cancelled')
      && !errorMessage.includes('auth/account-exists-with-different-credential')
      && !errorMessage.includes('auth/network-request-failed')
      && !errorMessage.includes('auth/popup-blocked')
      && !errorMessage.includes('auth/invalid-credential')
    ) {
      sendCloudWatchAlert(`Could not login with ${loginMethod}: ${errorMessage}`);
    }
    dispatch({
      type: USER_CONNECTION_ERROR,
      payload: `Nous n'avons pas pu vous connecter via ${loginMethod}! Veuillez réessayer`
    });
  }
};


export const userSignUp =
  (email, password, nom, prenom, telephone, phoneObj, newsletter) =>
  async (dispatch, getState) => {
    try {
      sendCloudWatchLogs(`User ${email} getting sign up`);
      dispatch({ type: USER_START_CONNECTION });
      const customerId = await signUpUser(
        email,
        password,
        nom,
        prenom,
        telephone,
        phoneObj,
        newsletter
      );
      dispatch({ type: USER_SIGNUP_ERROR, payload: '' });
      dispatch({
        type: SHOW_SIGNUP,
        payload: false,
      });
      dispatch({
        type: SHOW_CONNEXION_MODAL,
        payload: false,
      });
      dispatch({
        type: EMAIL,
        payload: null,
      });
      userConnectionSuccess(dispatch, getState, customerId, false);
      sendGoogleAnalytics('sign_up', { method: 'firebase' });
      analyticsEvents.logSignUp(AUTH_TYPES.INTERNAL)
    } catch (error) {
      deleteCustomerAccountBackend();
      userSignOutFromRedux(dispatch, getState);
      let errorMessage = String(error);
      if (!error) {
        errorMessage = 'auth/error-signup';
      }
      if (
        !errorMessage.includes('auth/email-already-in-use') &&
        !errorMessage.includes('auth/weak-password')
      ) {
        sendCloudWatchAlert(`Could not signup ${errorMessage}`);
      }
      dispatch({ type: USER_SIGNUP_ERROR, payload: errorMessage });
    }
  };

export const resetEmail = () => (dispatch) => {
  dispatch({ type: EMAIL, payload: null });
};

export const userForgetPassword = (email) => async (dispatch) => {
  try {
    sendCloudWatchLogs(`User ${email} resets his passwoard`);
    dispatch({ type: USER_START_RESET_PASSWORD });
    await forgetPasswordUser(email);
    dispatch({ type: RESET_PASSWORD_ERROR, payload: '' });
    dispatch({
      type: SHOW_FORGET_PASSWORD,
      payload: false,
    });
  } catch (error) {
    const errorMessage = String(error);
    if (!errorMessage.includes('user-not-found')) {
      sendCloudWatchAlert(`Could not forget password ${errorMessage}`);
    }

    dispatch({ type: RESET_PASSWORD_ERROR, payload: errorMessage });
  }
};

export const listenerAuth = () => {
  onAuthStateChanged(getFirebaseAuth(), async (user) => {
    const { uid } = user || {};
    if (!uid) {
      store.dispatch(userSignOut());
      return;
    }
    await getIdToken();
    const userExists = await apiService.userExists(uid);
    if (userExists) {
      await userConnectionSuccess(store.dispatch, store.getState, uid, user.isAnonymous);
    } else {
      store.dispatch(userSignOut());
    }
  });
};


export const userConnectionCheck = async () => {
  try {
    if (isWebView() || isQrCode()) {
      await userSignOutFromRedux(store.dispatch, store.getState);
      if (isWebView()) {
        let customerId = getCookieCustomerId();
        if (customerId) {
          const { customerAddress: address, shopId: pendingOrderShopId } =
            await getCustomerAddress(customerId, true);
          const shopId = getShopIdFromLink();
          if ((address && pendingOrderShopId !== shopId) || !address) {
            customerId = null;
          }
        }
        customerId = getWebViewCustomerId();
        await userConnectionSuccess(
          store.dispatch,
          store.getState,
          customerId,
          true
        );
        return;
      }
      try {
        sendCloudWatchLogs('Creating customer backend');
        await createCookieCustomer(ORDER_TYPE_CLICK_AND_SEAT);
      } catch (error) {
        sendCloudWatchAlert(`Could not create customer backend ${error}`);
      }
    } else listenerAuth();
  } catch (error) {
    sendCloudWatchAlert(`Error checking user ${error}`);
  }
};

export const userSignOut = () => async (dispatch, getState) => {
  try {
    const { user } = getState().userReducer;
    if (user) {
      unlistenFirebase(user.customerId);
    }
    await signOut();
    dispatchDataFromCookies(dispatch);
    dispatch({ type: USER_SIGNOUT });
    dispatch({ type: RESET_ORDER });
    showSplash(dispatch, false);
    analyticsEvents.logLogout();
  } catch (error) {
    sendCloudWatchAlert('Could not sign out');
  }
};

export const userSignOutFromRedux = async (dispatch, getState) => {
  try {
    const { user } = getState().userReducer;
    if (user) {
      unlistenFirebase(user.customerId);
    }
    await signOut();
    const newCustomerAddress = new Cookies().get('address');
    updateAddress(newCustomerAddress, dispatch);
    resetCookieCustomerId();
    dispatch({ type: USER_SIGNOUT });
    dispatch({ type: RESET_ORDER });
  } catch (error) {
    sendCloudWatchAlert('Could not sign out');
  }
};

export const userConnectionSuccess = async (
  dispatch,
  getState,
  customerId,
  isUserAnonymous
) => {
  await getIdToken();
  const userExists = await apiService.userExists(customerId);
  let customerProfile;
  if (userExists) {
    customerProfile = await apiService.usersGetOne([customerId]);
  }
  if (!customerProfile) {
    customerProfile = await getCustomerBackend();
  }
  if (customerProfile) {
    const { commande, address: customerAddress } =
      getState().pendingOrderReducer;
    if (!_.isEmpty(commande) && userExists) {
      await apiService.pendingOrdersUpsert([customerId, 'commande'], commande, {
        updateMode: 'replace',
      });
    }
    // Update address (address from cookie that will be used as the new address)
    await addAddress(
      dispatch,
      getState,
      customerAddress,
      customerId,
      isUserAnonymous
    );
    // Listen real time firebase data (needs to be done after updating address)
    await listenFirebaseUsersData(customerId, isUserAnonymous, updateUser);

    // Update user info
    updateUser(customerProfile, customerId, isUserAnonymous, dispatch);
    // hide splash
  } else {
    await sendCloudWatchAlert(`User ${customerId} without data`);
    await userSignOutFromRedux(dispatch, getState);
    dispatch({ type: USER_CONNECTION_ERROR, payload: 'auth/no-user-data' });
  }
  showSplash(dispatch, false);
};

export const updateUser = (userInfo, customerId, isUserAnonymous, dispatch) => {
  if (dispatch) {
    dispatch({
      type: USER_CONNECTION,
      payload: {
        user: { ...userInfo, customerId },
        isUserAnonymous,
      },
    });
  } else {
    return {
      type: USER_CONNECTION,
      payload: {
        user: { ...userInfo, customerId },
        isUserAnonymous,
      },
    };
  }
};

export const updateProfile = (attribute, value, dispatch) => {
  if (dispatch) {
    dispatch({ type: UPDATE_PROFILE, payload: { attribute, value } });
  } else {
    return { type: UPDATE_PROFILE, payload: { attribute, value } };
  }
};

export const resetLogin = () => ({
  type: RESET_LOGIN,
});

export const getSignUpData = (signUpData) => {
  return {
    type: GET_SIGNUP_DATA,
    payload: signUpData,
  };
};
