import { addPromotionBackend } from '../../api'
import { checkMinBasketPrice, isStringNotNull, renderErrorMessage, sendCloudWatchLogs } from '../../utils'
import store from '../../redux/store'
import { showMessageModal } from '../../redux/actions'
import { LOYALTY, LOYALTY_TYPE } from '../../constants'
import { analyticsEvents } from '../../utils/firebaseAnalytics'

export const addLoyalty = async (component, loyalty, totalPriceOrder, loyaltyShop) => {
  try {
    const minBasketPrice = checkMinBasketPrice(loyalty, totalPriceOrder)
    const { user } = store.getState().userReducer;
    const { customerId, points } = user;
    component.setState({ loadingPromotion: true });
    if (loyaltyShop.disabled) store.dispatch(showMessageModal(`Ce restaurant n'est pas éligible au programme de fidélité. Vous pouvez utiliser votre code promotionnel dans un autre point de vente du réseau.`));
    else {
     component.setState({ loadingPromotion: true });
    if (isStringNotNull(loyalty)) {
      sendCloudWatchLogs(`Adding loyalty ${loyalty}`);
      const deviceId = customerId;
      analyticsEvents.logLoyaltyUsed(points)
      await addPromotionBackend(customerId, loyalty, deviceId);
      if (loyalty === LOYALTY)
        store.dispatch(showMessageModal(`Vos points de fidélité ont été consommés ${minBasketPrice ? `pour 
        pouvoir profiter de ce code promotionnel votre panier doit être superieur à ${minBasketPrice}€` : '' }`));
      component.setState({ loadingPromotion: false });
    }
}
  } catch (error) {
    store.dispatch(showMessageModal(renderErrorMessage(error)));
    component.setState({ loadingPromotion: false });
    sendCloudWatchLogs(`Error adding loyalty ${error}`);
  }
};

export const getLoyaltyCashback = (companyLoyaltyConfig) =>
  (!companyLoyaltyConfig?.enabled || companyLoyaltyConfig?.type !== LOYALTY_TYPE.CASHBACK)
    ? null
    : companyLoyaltyConfig?.cashbackConfig;

export const getLoyaltyPercentage = (points, loyalty) => {
  let percentage = Math.round((points / loyalty.limit) * 100);
  if (percentage > 100) {
    percentage = 100;
  }
  return percentage;
};
