import { analytics } from '../../src/api/firebase/firebaseInit';
import { logEvent } from 'firebase/analytics';
import { sendCloudWatchAlert } from './logs';

const EVENT_NAMES = {
  SIGN_UP: 'sign_up',
  LOGIN: 'login',
  ADD_TO_CART: 'add_to_cart',
  REMOVE_FROM_CART: 'remove_from_cart',
  ADD_PAYMENT_INFO: 'add_payment_info',
  VIEW_ITEM: 'view_item',
  UPDATE_PROFILE: 'update_profile',
  DELETE_ACCOUNT: 'delete_account',
  LOGOUT: 'logout',
  SELECT_SALE_MODE: 'select_sale_mode',
  SELECT_SHOP: 'select_shop',
  SELECT_ADDRESS: 'select_address',
  CONFIRM_SHOP_SELECTION: 'confirm_shop_selection',
  SECTION_CLICK: 'section_click',
  ADD_PROMO_CODE: 'add_promo_code',
  USE_LOYALTY: 'use_loyalty',
  PURCHASE: 'purchase',
  ORDER_TRACKING: 'order_tracking',
  PAGE_VIEW: 'page_view',
};

export const PAGES = {
  SALES_MODE: 'Sales Mode Page',
  PROFILE: 'Profile Page',
  PRODUCT: 'Product Page',
  CART: 'Cart Page',
  PAYMENT: 'Payment Page',
  EDIT_PROFILE: 'Edit Profile Page',
  ORDER_HISTORY: 'Order History Page',
  SIGN_UP: 'Registration Page',
  LOGIN: 'Login Identification Page',
  FINAL_LOGIN: 'Final Login Page',
};

export const PROVENANCE = {
  SALES_MODE_BUTTON: 'sales mode button',
  ADDRESS_BUTTON: 'address button',
  PRODUCT_CLICK: 'product click (first time)',
  PROFILE_NAV_BUTTON: 'profile navigation button',
  BACK_BUTTON: 'back button',
  MENU_PAGE: 'menu page',
  CART_PAGE: 'cart page',
  LOGIN_PAGE: 'login identification page',
  FINAL_LOGIN_PAGE: 'final login page',
  PROFILE_PAGE: 'profile page',
  ORDER_HISTORY_PAGE: 'order history page',
};

export const PAGE_TYPES = {
  INTERNAL: 'internal',
  EXTERNAL: 'external',
};


// Generic function to log a custom event
const logCustomEvent = (eventName, eventParams = {}) => {
  if (analytics) {
    logEvent(analytics, eventName, eventParams);
  } else {
    sendCloudWatchAlert('Firebase Analytics not initialized');
  }
};

// Grouped functions for logging events
export const analyticsEvents = {
  logSignUp: (method) => logCustomEvent(EVENT_NAMES.SIGN_UP, { method }),
  logLogin: (method) => logCustomEvent(EVENT_NAMES.LOGIN, { method }),
  logAddToCart: (item_id, item_name, price, item_category, quantity, position, currency = 'EUR') =>
    logCustomEvent(EVENT_NAMES.ADD_TO_CART, { item_id, item_name, price, item_category, quantity, position, currency }),
  logRemoveFromCart: (item_id, item_name, price, item_category, quantity, position) =>
    logCustomEvent(EVENT_NAMES.REMOVE_FROM_CART, { item_id, item_name, price, item_category, quantity, position }),
  logPaymentInfo: (selected_card) => logCustomEvent(EVENT_NAMES.ADD_PAYMENT_INFO, { selected_card }),
  logViewItem: (provenance, item_id, item_name, price, item_category, position, currency = 'EUR') =>
    logCustomEvent(EVENT_NAMES.VIEW_ITEM, { provenance, item_id, item_name, price, item_category, position, currency }),
  logProfileUpdate: (customer_id) => logCustomEvent(EVENT_NAMES.UPDATE_PROFILE, { customer_id }),
  logAccountDeletion: (customer_id) => logCustomEvent(EVENT_NAMES.DELETE_ACCOUNT, { customer_id }),
  logLogout: () => logCustomEvent(EVENT_NAMES.LOGOUT, {}),
  logSelectSaleMode: (type) => logCustomEvent(EVENT_NAMES.SELECT_SALE_MODE, { type }),
  logShopSelection: (shop_id) => logCustomEvent(EVENT_NAMES.SELECT_SHOP, { shop_id }),
  logAddressSelection: (address) => logCustomEvent(EVENT_NAMES.SELECT_ADDRESS, { address }),
  logShopSelectionConfirmed: (shop_id) => logCustomEvent(EVENT_NAMES.CONFIRM_SHOP_SELECTION, { shop_id }),
  logSectionClick: (section_id) => logCustomEvent(EVENT_NAMES.SECTION_CLICK, { section_id }),
  logPromoCodeAdded: (provenance, type) => logCustomEvent(EVENT_NAMES.ADD_PROMO_CODE, { provenance, type }),
  logLoyaltyUsed: (points_used) => logCustomEvent(EVENT_NAMES.USE_LOYALTY, { points_used }),
  logPurchase: (coupon, shipping, tax, payment_mode, price, sale_mode, city, day, hour, isScheduled) =>
    logCustomEvent(EVENT_NAMES.PURCHASE, { coupon, shipping, tax, payment_mode, price, sale_mode, city, day, hour, isScheduled }),
  logOrderTracking: (step, step_label) => logCustomEvent(EVENT_NAMES.ORDER_TRACKING, { step, step_label }),
  logPageViewEvent: (page, provenance, type = '') => logCustomEvent(EVENT_NAMES.PAGE_VIEW, { page, provenance, type }),
};
