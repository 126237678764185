import { addPromotionBackend } from '../../api';
import {
  checkMinBasketPrice,
  isStringNotNull,
  renderErrorMessage,
  sendCloudWatchLogs,
} from '../../utils';
import store from '../../redux/store';
import { showMessageModal } from '../../redux/actions';
import { analyticsEvents } from '../../utils/firebaseAnalytics'

export const addPromotion = async (
  setLoadingPromotion: React.Dispatch<React.SetStateAction<boolean>>,
  promoCode: string,
  totalPriceOrder: number
) => {
  const minBasketPrice = await checkMinBasketPrice(promoCode, totalPriceOrder);
  try {
    const { user } = store.getState().userReducer;
    const { customerId } = user;
    setLoadingPromotion(true);
    if (isStringNotNull(promoCode)) {
      sendCloudWatchLogs(`Adding code promo ${promoCode}`);
      await addPromotionBackend(customerId, promoCode, customerId);
      analyticsEvents.logPromoCodeAdded('cartView', 'promoCode')
      store.dispatch(
        showMessageModal(
          `Votre code promotionnel ${promoCode} a bien été ajouté. ${
            minBasketPrice
              ? ` Pour 
      profiter de ce code promotionnel, votre panier doit être supérieur à ${minBasketPrice}€`
              : ''
          }`
        )
      );
      setLoadingPromotion(false);
    } else {
      store.dispatch(
        showMessageModal(`Vous devez entrer un code promotionnel.`)
      );
      setLoadingPromotion(false);
    }
  } catch (error) {
    const errorMessage = String(error);
    const promotion = store.getState().shopReducer.promotions?.[promoCode];
    setLoadingPromotion(false);
    let message = renderErrorMessage(errorMessage, null);
    if (
      promotion &&
      Object.hasOwn(promotion, 'startedDate') &&
      Object.hasOwn(promotion, 'endedDate')
    ) {
      const validDates = {
        startedDate: promotion.startedDate,
        endedDate: promotion.endedDate,
      };
      message = renderErrorMessage(errorMessage, validDates);
    }
    store.dispatch(showMessageModal(message));
    sendCloudWatchLogs(`Error adding promotion ${error}`);
  }
};
