import { useStateObject } from '../../hooks/useStateObject';
import _ from 'lodash';
import StoreIcon from '@mui/icons-material/Store';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCart';
import PersonOutlineOutlinedIcon from '@mui/icons-material/Person';
import LocalMallIcon from '@mui/icons-material/LocalMall';
import FlagsSelect from '../FlagsSelect';
import Button from '../Button';
import { useAppDispatch, useAppSelector } from '../../redux/hook';
import { BLACK, DARK_GRAY, PRIMARY, UNIT, WHITE } from '../../constants';
import {
  getNbTotalCommande,
  isDarkTheme,
  isQrCode,
  isStringNotNull,
  sendCloudWatchLogs
} from '../../utils';
import {
  showAccountModal,
  showAddressModal,
  showCartModal,
  showConnexionModal,
  showOrderModal,
} from '../../redux/actions';
import { getAddress, getOrderTypeLabel } from './InfoBar.services';
import { DISPLAY_LARGE, DISPLAY_SMALL } from './InfoBar.styles';
import React from 'react';
import { isCustomerAddressDefined } from '../../utils/customer-address-and-shops';
import { analyticsEvents, PAGES, PROVENANCE } from '../../utils/firebaseAnalytics';
const INITIAL_BACKGROUND_COLOR = 'transparent';

export default function InfoBar() {

  const [backgroundsColors, setBackgroundsColors] = useStateObject({
    cartIcon: INITIAL_BACKGROUND_COLOR,
    accountIcon: INITIAL_BACKGROUND_COLOR,
    locationIcon: INITIAL_BACKGROUND_COLOR,
    orderIcon: INITIAL_BACKGROUND_COLOR,
    orderTypeIcon: INITIAL_BACKGROUND_COLOR
  });

  const { isHorizontalSection, isMobile, customerAddressCheck } = useAppSelector(state => state.componentReducer);
  const { userConnected, isUserAnonymous } = useAppSelector(state => state.userReducer) as any;
  const { orderType, address: customerAddress } = useAppSelector(state => state.pendingOrderReducer) as any;
  const { secondaryColor, localize, landing, } = useAppSelector(state => state.configurationReducer) as any;
  const { shopName } = useAppSelector(state => state.shopReducer) as any;
  const { orders } = useAppSelector(state => state.orderReducer);
  const { commande } = useAppSelector(state => ({ commande: { ...state.pendingOrderReducer?.commande } }));
  const dispatch = useAppDispatch();
  const orderTypeLabel = getOrderTypeLabel(orderType, isMobile);

  const handleMouseEnter = element => {
    setBackgroundsColors({ [element]: secondaryColor });
  };

  const handleMouseLeave = element => {
    setBackgroundsColors({ [element]: INITIAL_BACKGROUND_COLOR });
  };

  const handleCartIconClick = () => {
    const customerAddressDefined = isCustomerAddressDefined(customerAddress)
    if (!customerAddressDefined) {
      sendCloudWatchLogs('Cart without address');
    }
    dispatch((customerAddressDefined && customerAddressCheck) ? showCartModal(true) : showAddressModal(true))
  };

  const handleShopAddressOrderTypeClick = () => {
      dispatch(showAddressModal(true))
  };

  return (
    <div
      className='px-3 px-sm-5 d-block text-white'
      style={{
        backgroundColor:
					(isDarkTheme() && !isHorizontalSection) || !isDarkTheme() ? DARK_GRAY : BLACK
      }}
    >
      <div className='row p-0 p-md-3 py-3 py-md-0 justify-content-between'>
        <div className={` ${isMobile && 'col-7'} row align-self-center`}>
          <Button
            className='row col-12 d-flex align-items-center p-0 no-outline'
            onClick={() => handleShopAddressOrderTypeClick()}
            style={{
              backgroundColor: backgroundsColors['orderTypeIcon'],
              pointerEvents: (isUserAnonymous && !isQrCode()) || !isStringNotNull(orderTypeLabel) ? 'none' : 'auto',
              borderRadius: UNIT / 4
            }}
            onMouseEnter={() =>
              (!isUserAnonymous || isQrCode()) &&
							isStringNotNull(orderTypeLabel) &&
							handleMouseEnter('orderTypeIcon')
            }
            onMouseLeave={() => (!isUserAnonymous || isQrCode()) && handleMouseLeave('orderTypeIcon')}
          >
            <div className={`${isMobile && 'text-truncate'} text-white mx-0 mx-sm-1 align-items-center`}>
              {!isMobile ? (
                <p
                  className='mb-0 ml-0'
                  style={{
                    visibility: `${isStringNotNull(orderTypeLabel) ? 'visible' : 'hidden'}`
                  }}
                >
                  {isStringNotNull(orderTypeLabel) ? orderTypeLabel : 'Commande sur Place'}
                </p>
              ) : (
                <>
                  <span
					  className='mb-0 mr-3 no-localization addressStyle'
					  style={{
						  whiteSpace: 'nowrap',
						  overflow: 'hidden',
						  textOverflow: 'ellipsis'
					  }}
				  >
                    <LocationOnIcon style={{ marginTop: `${UNIT * -0.18755}` }}/>
					  {getAddress()}
                  </span>
                </>
              )}
            </div>
          </Button>
        </div>
        <div className='d-none d-lg-block'>
          <div className='row'>
            <div>
              <Button
                className='row d-flex align-items-center p-0 text-white'
                onClick={() => handleShopAddressOrderTypeClick()}
                style={{
                  backgroundColor: backgroundsColors['locationIcon'],
                  pointerEvents: isUserAnonymous ? 'none' : 'auto',
                  borderRadius: UNIT / 4
                }}
                onMouseEnter={() => !isUserAnonymous && handleMouseEnter('locationIcon')}
                onMouseLeave={() => !isUserAnonymous && handleMouseLeave('locationIcon')}
              >
                <LocationOnIcon className='mr-2'/>
                <p className='mb-0 mr-3 no-localization'>{getAddress()}</p>
                <p className='mb-0 mr-3'>|</p>
                <StoreIcon className='mr-2'/>
                <p className='mb-0 pr-1'>{shopName}</p>
              </Button>
            </div>
          </div>
        </div>
        <div className='d-flex'>
          <>
            <Button
              className='p-0 no-outline'
              disabled={_.isEmpty(commande)}
              onClick={handleCartIconClick}
              style={{ backgroundColor: backgroundsColors['cartIcon'], borderRadius: UNIT / 4 }}
              onMouseEnter={() => handleMouseEnter('cartIcon')}
              onMouseLeave={() => handleMouseLeave('cartIcon')}
            >
              <div
                className='row text-white mx-0 mx-sm-1 align-items-center'
                style={{ position: 'relative' }}
              >
                <ShoppingCartOutlinedIcon className='mr-0 mr-xl-1'/>
                {!_.isEmpty(commande) && (
                  <div
                    className={DISPLAY_SMALL}
                    style={{
                      position: 'absolute',
                      zIndex: 1000,
                      right: -(UNIT / 2),
                      top: -10,
                      backgroundColor: secondaryColor,
                      borderRadius: 50,
                      width: 20
                    }}
                  >
                    <p className='mb-0 text-white' style={{ fontSize: UNIT * 0.875 }}>
                      {getNbTotalCommande()}
                    </p>
                  </div>
                )}
                <p className={DISPLAY_LARGE}>{`${getNbTotalCommande()} Panier`}</p>
              </div>
            </Button>
            {isMobile && !_.isEmpty(commande) && (
              <Button
                type={PRIMARY}
                className='fixed-cart-bottom'
                onClick={handleCartIconClick}
                style={{ backgroundColor: secondaryColor, borderRadius: UNIT / 4 }}
              >
                <ShoppingCartOutlinedIcon className='mr-xl-1 float-left leftSpace'/>
								Voir panier
                <div
                  className={DISPLAY_SMALL}
                  style={{
                    position: 'absolute',
                    zIndex: 1000,
                    right: '13%',
                    top: 8,
                    backgroundColor: WHITE,
                    borderRadius: 50,
                    width: 20
                  }}
                >
                  <p
                    className='mb-0'
                    style={{
                      fontSize: UNIT * 0.875,
                      color: secondaryColor
                    }}
                  >
                    {getNbTotalCommande()}
                  </p>
                </div>
              </Button>
            )}
          </>
            <>
              <div className='mr-1'/>
              <Button
                className='p-0 no-outline'
                disabled={_.isEmpty(orders)}
                onClick={() => {
                  dispatch(showOrderModal(true))
                }}
                onMouseEnter={() => handleMouseEnter('orderIcon')}
                onMouseLeave={() => handleMouseLeave('orderIcon')}
                style={{ backgroundColor: backgroundsColors['orderIcon'], borderRadius: UNIT / 4 }}
              >
                <div
                  className='row text-white mx-0 mx-sm-1 align-items-center'
                  style={{ position: 'relative' }}
                >
                  <LocalMallIcon className='mr-0 mr-xl-1'/>
                  {!_.isEmpty(orders) && (
                    <div
                      className={DISPLAY_SMALL}
                      style={{
                        position: 'absolute',
                        zIndex: 1000,
                        right: -(UNIT / 2),
                        top: -10,
                        backgroundColor: secondaryColor,
                        borderRadius: 50,
                        width: 20
                      }}
                    >
                      <p className='mb-0 text-white' style={{ fontSize: UNIT * 0.875 }}>
                        {_.size(orders)}
                      </p>
                    </div>
                  )}
                  <p className={DISPLAY_LARGE}>
                    {_.size(orders) <= 1
                      ? `${_.size(orders)} Commande`
                      : `${_.size(orders)} Commandes`}
                  </p>
                </div>
              </Button>
            </>
          {!isUserAnonymous && (
            <>
              <div className='mr-1'/>
              <Button
                className='p-0 no-outline'
                onClick={() => {
                  userConnected ? dispatch(showAccountModal(true)) : dispatch(showConnexionModal(true))
                  analyticsEvents.logPageViewEvent(PAGES.PROFILE, PROVENANCE.PROFILE_NAV_BUTTON);
                  }
                }
                onMouseEnter={() => handleMouseEnter('accountIcon')}
                onMouseLeave={() => handleMouseLeave('accountIcon')}
                style={{ backgroundColor: backgroundsColors['accountIcon'], borderRadius: UNIT / 4 }}
              >
                <div className='row text-center text-white mx-0 mx-sm-1 align-items-center'>
                  <PersonOutlineOutlinedIcon className='mr-0 mr-xl-1'/>
                  <p className={DISPLAY_LARGE}>Profil</p>
                </div>
              </Button>
              {!isMobile && (
                <Button
                  className='p-0 no-outline ml-2 d-block d-lg-none align-self-center'
                  onClick={() => dispatch(showAddressModal(true))}
                  onMouseEnter={() => handleMouseEnter('locationIcon')}
                  onMouseLeave={() => handleMouseLeave('locationIcon')}
                  style={{ backgroundColor: INITIAL_BACKGROUND_COLOR, borderRadius: UNIT / 4 }}
                >
                  <div className='row text-center text-white mx-0 mx-sm-1 align-items-center'>
                    <LocationOnIcon className='mr-0 mr-xl-1'/>
                    <p className={`pr-1 ${DISPLAY_LARGE}`}>Addresse</p>
                  </div>
                </Button>
              )}
            </>
          )}
          {(!landing.enabled || isUserAnonymous) && localize.enabled && (
            <div className='ml-2 align-self-center'>
              <FlagsSelect
                className='mx-0 mx-sm-1'
                showSelectedLabel={false}
                alignOptions='left'
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
