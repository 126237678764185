import React from 'react';
import { generateCounterOptionKey } from '../../../../../../../../../../../../../utils';
import { handleCounterClick } from '../../../Category.services';
import OptionLabel from '../../OptionLabel';
import MultiOptionsButtons from '../../MultiOptionsButtons';
import _ from 'lodash';

type MultipleItemProps = {
  category:any;
  itemKey:any;
  itemsCounter:any;
  productItem:any;
  item:any;
  concernedProductItem:any;
  totalMaxItemsSelected:any;
  notEnoughStock:any;
  optionContainerWebapp:any;
  index:any;
  addItem:any;
  removeItem:any;
  product:any;
  categoryKey:any;
};

export default function MultipleItem({
  category,
  itemKey,
  itemsCounter,
  productItem,
  item,
  concernedProductItem,
  totalMaxItemsSelected,
  notEnoughStock,
  optionContainerWebapp,
  index,
  addItem,
  removeItem,
  product,
  categoryKey,
}: MultipleItemProps) {
  const optionKey = generateCounterOptionKey(category.key, itemKey);
  const counter = itemsCounter[optionKey] || 0;
  const currentItemSelected = _.filter(
    productItem?.options,
    (option) => option.key === item.key
  );
  const multipleMaxItemSelected =
    concernedProductItem && category?.multipleMax
      ? _.size(currentItemSelected) >= category?.multipleMax
      : false;
  const counterDisabled =
    totalMaxItemsSelected || notEnoughStock || multipleMaxItemSelected;
  return (
    <div
      className={optionContainerWebapp}
      style={{ cursor: !counterDisabled ? 'pointer' : 'auto' }}
    >
      <div
        className={`h-100`}
        onClick={() => {
          if (!counterDisabled) {
            handleCounterClick({
              index,
              addItem,
              removeItem,
              item,
              category,
              action: 'add',
            });
          }
        }}
      >
        <div className={`row h-100 d-flex align-items-center`}>
          <OptionLabel
            item={item}
            notAvailable={counterDisabled}
            product={product}
            categoryKey={categoryKey}
          />
          <MultiOptionsButtons
            counter={counter}
            item={item}
            counterDisabled={counterDisabled}
            index={index}
            addItem={addItem}
            removeItem={removeItem}
            category={category}
          />
        </div>
      </div>
    </div>
  );
}
