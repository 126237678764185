import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import {
  showUpdateInfo,
  showMessageModal,
  showAccountModal,
  userSignOut,
  updateUser,
  updateProfile,
} from '../../redux/actions';
import { isStringNotNull } from '../../utils';
import {
  deleteUserAccount,
  updateAccount,
  validateForm,
} from './AccountForm.services';
import { UNIT, PRIMARY, SECONDARY, LINK } from '../../constants/configuration';
import { TEXT_VALIDATION_DELETE_ACCOUNT } from '../../constants';
import Button from '../Button';
import PhoneInput from '../PhoneInput';
import Spinner from '../Spinner';
import { analyticsEvents, PAGES, PROVENANCE } from '../../utils/firebaseAnalytics'
import { Field, Form } from 'react-final-form';
import { useAppDispatch, useAppSelector } from '../../redux/hook';
import _ from 'lodash';

export default function AccountForm() {
  const dispatch = useAppDispatch();

  const { user, isUserAnonymous } = useAppSelector(
    (state) => state.userReducer
  );
  const { firstName, lastName, numero } = user;

  const [loading, setLoading] = useState(false);
  const [isPhoneIncorrect, setIsPhoneIncorrect] = useState(true);
  const [phone, setPhone] = useState('');
  const [phoneObj, setPhoneObj] = useState({});

  const renderPhoneInput = ({ meta, input, label }) => {
    const { value, onBlur } = input;
    const className = `form-control ${
      (isPhoneIncorrect || !isStringNotNull(phone)) && meta.touched
        ? 'is-invalid'
        : ''
    }`;

    return (
      <div className='form-group'>
        <p className='text-muted mb-2'>{label}</p>
        <PhoneInput
          onChange={(phone, country, e, formattedPhone) => {
            setPhone(phone);
            input.onChange(formattedPhone);
          }}
          value={value}
          onBlur={onBlur}
          className={`${className} no-localization dark-modal`}
          isPhoneIncorrect={(isPhoneIncorrect) => {
            setIsPhoneIncorrect(isPhoneIncorrect);
          }}
          getPhoneObj={(obj) =>
            _.isEqual(obj, phoneObj) ? null : setPhoneObj(obj)
          }
        />
        {(isPhoneIncorrect || !isStringNotNull(phone)) && meta.touched && (
          <div className='invalid-feedback-block'>
            Veuillez fournir un telephone valide
          </div>
        )}
      </div>
    );
  };

  const renderInput = ({
    input,
    type,
    placeholder,
    meta,
    description,
    label,
  }) => {
    const className = `form-control ${
      meta.error && meta.touched ? 'is-invalid' : ''
    }`;
    return (
      <div className='form-group'>
        <p className='text-muted mb-2'>{label}</p>
        <input
          type={type}
          className={`${className} no-localization dark-modal`}
          placeholder={placeholder}
          {...input}
        />
        <small className='form-check-label'>{description}</small>
        <div className='invalid-feedback'>{meta.error}</div>
      </div>
    );
  };

  const onSubmit = ({ firstName, lastName, numero }) => {
    if (!isPhoneIncorrect && isStringNotNull(numero)) {
      updateAccount({
        setLoading,
        phoneObj,
        updateProfile,
        showMessageModal,
        showUpdateInfo,
        user,
        firstName,
        lastName,
        numero,
        dispatch,
      });
      analyticsEvents.logProfileUpdate(user.customerId);
    }
  };

  return (
    <Form
      validate={validateForm}
      onSubmit={onSubmit}
      initialValues={{ firstName, lastName, numero }}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Modal.Body className='pb-2'>
            <div className='p-0 p-sm-2'>
              <Field
                name='firstName'
                type='text'
                placeholder={firstName}
                render={renderInput}
                label='Prénom'
              />
              <Field
                name='lastName'
                type='text'
                placeholder={lastName}
                render={renderInput}
                label='Nom de famille'
              />
              <Field
                name='numero'
                type='text'
                placeholder={numero}
                render={renderPhoneInput}
                label='Numéro de téléphone'
              />
              <div className='text-center'>
                <Button
                  type={LINK}
                  className='p-0'
                  style={{ fontSize: UNIT * 0.875 }}
                  onClick={() => {
                    dispatch(
                      showMessageModal(TEXT_VALIDATION_DELETE_ACCOUNT, {
                        onClick: () =>
                          deleteUserAccount({
                            showUpdateInfo,
                            showAccountModal,
                            showMessageModal,
                            userSignOut,
                            customerId: user.customerId,
                            isUserAnonymous,
                            updateUser,
                            dispatch,
                          }),
                        showCancel: true,
                      })
                    );
                     analyticsEvents.logAccountDeletion(user.customerId)
                  }}
                >
                  Supprimer mon compte
                </Button>
              </div>
            </div>
          </Modal.Body>
          {loading && (
            <Modal.Footer className='justify-content-center'>
              <Spinner />
            </Modal.Footer>
          )}
          {!loading && (
            <Modal.Footer className='justify-content-center'>
              <Button
                type={SECONDARY}
                onClick={() => {
                  dispatch(showUpdateInfo(false));
                  analyticsEvents.logPageViewEvent(PAGES.EDIT_PROFILE, PROVENANCE.BACK_BUTTON)
                }}
              >
                Retour
              </Button>
              <Button
                type={PRIMARY}
                isSubmit
              >
                Mettre à jour
              </Button>
            </Modal.Footer>
          )}
        </form>
      )}
    />
  );
}
