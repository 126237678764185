import _ from 'lodash';
import { SHOW_PRODUCTS, RESET_HANDLED_ITEMS, MARK_ITEM_AS_HANDLED } from './types';

export const showProducts = products => {
  const productsArray = [];

  _.keys(products).forEach(productKey => {
    productsArray.push(products[productKey]);
  });

  return {
    type: SHOW_PRODUCTS,
    payload: productsArray
  };
};
export const markItemAsHandled = (itemKey) => ({
  type: MARK_ITEM_AS_HANDLED,
  payload: itemKey,
});
export const resetHandledItems = () => ({
  type: RESET_HANDLED_ITEMS,
});

