import React from 'react';
import { formatPhone, getCreditCard, isObjectNotNull } from '../../../utils';
import { useAppDispatch, useAppSelector } from '../../../redux/hook';
import { Button, Modal } from 'react-bootstrap';
import { Fade } from 'react-awesome-reveal';
import PastOrders from '../../PastOrders';
import { PRIMARY, UNIT } from '../../../constants';
import { showPaymentModal, showUpdateInfo } from '../../../redux/actions';
import CreditCard from '../../CreditCard';
import { analyticsEvents, PAGES, PROVENANCE } from '../../../utils/firebaseAnalytics';

export default function AccountModalBody({ pastOrder, setPastOrder }) {
  const dispatch = useAppDispatch();

  const { user, userConnected } = useAppSelector((state) => state.userReducer);
  const { firstName, lastName, numero, email, cards } = user;
  const card = getCreditCard(cards);
  const iconPadding = 'pr-2';

  return !userConnected ? (
    <div>Se connecter</div>
  ) : (
    <Modal.Body className='dark-modal'>
      {pastOrder ? (
        <Fade>
          <PastOrders />
        </Fade>
      ) : (
        <div className='p-0 p-sm-2'>
          <h4 className='text-muted text-center mb-0 label'>Profil</h4>
          <div className='row'>
            <div className='col-12 col-sm-8 py-2 no-localization'>
              <i
                className={`fas fa-user ${iconPadding}`}
                style={{ fontSize: UNIT }}
              />
              {firstName || '--'} {lastName}
              <br />
              <i className={`fas fa-envelope ${iconPadding}`} />
              {email || '--'}
              <br />
              <i className={`fas fa-phone-alt ${iconPadding}`} />
              {formatPhone(numero) || '--'}
            </div>
            <div className='col-12 col-sm-4 pt-2 pt-sm-0 d-flex align-items-center'>
              <Button
                type={PRIMARY}
                className='btn-block label'
                onClick={() => {
                  dispatch(showUpdateInfo(true));
                }}
              >
                <i className='fas fa-user-cog pr-2' />
                Modifier
              </Button>
            </div>
          </div>
          <h4 className='text-muted text-center mt-3 label'>
            Cartes bancaires
          </h4>
          {card ? (
            <CreditCard buttonName='Modifier' />
          ) : (
            <div className='row align-items-center justify-content-center'>
              <Button
                type={PRIMARY}
                className='btn-block label'
                onClick={() => {
                  dispatch(showPaymentModal(true));
                }}
              >
                <i className='fas fa-credit-card pr-2' />
                {isObjectNotNull(cards)
                  ? 'Gestion de vos cartes bancaires'
                  : 'Ajouter une carte bancaire'}
              </Button>
            </div>
          )}
          <h4 className='text-muted text-center mt-3 label'>Mes commandes</h4>
          <Button
            type={PRIMARY}
            className='btn-block'
            onClick={() => {
              setPastOrder(true);
              analyticsEvents.logPageViewEvent(PAGES.ORDER_HISTORY, PROVENANCE.PROFILE_PAGE);
            }}
          >
            <i className='fas fa-cart-arrow-down pr-2' />
            Voir mes commandes
          </Button>
        </div>
      )}
    </Modal.Body>
  );
}
