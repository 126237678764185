import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import {
  convertHexToRGBA,
  filterSections,
  isDarkTheme,
  isStringNotNull,
} from '../../utils';
import { UNIT } from '../../constants';
import { updateMsgSectionRange } from './ListProduct.services';
import { useAppSelector } from '../../redux/hook';
import ProductList from './components/ProductList';
import { analyticsEvents, PAGES, PROVENANCE } from '../../utils/firebaseAnalytics';

export default function ListProduct() {
  const [messages, setMessages] = useState({});
  const [newSections, setNewSecitons] = useState([]);

  const { sections } = useAppSelector((state) => state.shopReducer);
  const { secondaryColor } = useAppSelector(
    (state) => state.configurationReducer
  );
  useEffect(() => {
    analyticsEvents.logPageViewEvent(PAGES.PRODUCT, PROVENANCE.MENU_PAGE);
  }, []);

  useEffect(() => {
    setNewSecitons(filterSections(sections));
  }, [sections]);

  useEffect(() => {
    const intervalId = setInterval(
      () => {updateMsgSectionRange(newSections, messages, setMessages);   setNewSecitons(filterSections(sections))},
      5000
    );

    return () => clearInterval(intervalId);
  }, [newSections]);

  return (
    <div id='list-products'>
      {newSections?.length && (
        <div className='col'>
          <div>
            {_.map(newSections, (section, index) => {
              let sectionClassList = `${`border-bottom ${
                isDarkTheme() && 'border-secondary'
              }`}`;
              if (index !== 0) {
                sectionClassList += ' pt-4';
              }
              return (
                <div
                  className={sectionClassList}
                  key={index}
                >
                  <div
                    className='target-section'
                    id={`${section.key}`}
                  >
                    <div
                      className={`border-bottom ${
                        isDarkTheme() && 'border-secondary'
                      }`}
                    >
                      <div className='row justify-content-center align-items-center'>
                        {isStringNotNull(section.iconUrl) && (
                          <img
                            alt={section.name}
                            src={section.iconUrl}
                            style={{ width: '40px', height: '40px' }}
                            className='mr-2 rounded-circle'
                          />
                        )}
                        <h4 className='font-weight-bold text-color text-center align-self-center mb-0 text-break'>
                          {!!section?.name &&
                            section.name[0].toUpperCase() +
                              section.name.slice(1).toLowerCase()}
                        </h4>
                      </div>
                      <div className='pb-4'>
                        {isStringNotNull(section.description) && (
                          <p className='text-color-secondary text-center align-self-center mb-0 text-break mt-1'>
                            {section.description}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  {messages?.[section.key] && (
                    <div
                      style={{
                        backgroundColor: convertHexToRGBA(secondaryColor, 0.2),
                      }}
                    >
                      <h4
                        style={{
                          textAlign: 'center',
                          color: secondaryColor,
                          fontSize: 14,
                          padding: UNIT * 0.8,
                        }}
                      >
                        {`Les produits de ${
                          section.name
                        } seront bientôt disponibles. La prochaine plage d'activation est ${
                          messages[section.key]
                        }.`}
                      </h4>
                    </div>
                  )}
                  <div className='row my-3'>
                    <ProductList
                      section={section}
                      messages={messages}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
}
