import _ from 'lodash';
import Modal from 'react-bootstrap/Modal';
import React, { useEffect, useState } from 'react';
import Button from '../Button';
import AddPayment from '../AddPayment';
import Spinner from '../Spinner';
import { showPaymentModal } from '../../redux/actions';
import { changeDefaultCreditCard } from './PaymentModal.services';
import { SECONDARY } from '../../constants';
import { addCustomerCard, sendGoogleAnalytics } from '../../api';
import ModalBorder from '../ModalBorder';
import { useAppDispatch, useAppSelector } from '../../redux/hook';
import CreditCards from './components/CreditCards';
import { analyticsEvents, PAGES, PROVENANCE } from '../../utils/firebaseAnalytics';

export default function PaymentModal({ stripe, elements }) {
  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState(false);
  const [selectedCard, setSelectedCard] = useState({});

  const { paymentModal } = useAppSelector((state) => state.componentReducer);
  const { secondaryColor } = useAppSelector(
    (state) => state.configurationReducer
  );
  const {
    user: { cards },
  } = useAppSelector((state) => state.userReducer);

  useEffect(() => {
      if (paymentModal) {
        analyticsEvents.logPageViewEvent(PAGES.PAYMENT, PROVENANCE.CART_PAGE);
      }
    }, [paymentModal]);

  return (
    <Modal
      show={paymentModal}
      onHide={() => {
        if (!loading) {
          dispatch(showPaymentModal(false));
        }
      }}
    >
      <ModalBorder>
        <Modal.Body className='dark-modal'>
          <div>
            {cards && !_.isEmpty(cards) && (
              <div>
                <div className='mb-4'>
                  {
                    <CreditCards
                      selectedCard={selectedCard}
                      setSelectedCard={setSelectedCard}
                    />
                  }
                </div>
              </div>
            )}
            <div className='mt-4'>
              <AddPayment />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className='justify-content-center dark-modal'>
          {loading && <Spinner />}
          {!loading && (
            <>
              <Button
                type={SECONDARY}
                onClick={() => {
                  dispatch(showPaymentModal(false));
                }}
              >
                Fermer
              </Button>
              <Button
                type={SECONDARY}
                style={{
                  backgroundColor: secondaryColor,
                  borderColor: secondaryColor,
                }}
                onClick={async () => {
                  if (
                    _.isEmpty(selectedCard) ||
                    !cards ||
                    !cards[selectedCard.id]
                  ) {
                    setLoading(true);
                    const card = await addCustomerCard(stripe, elements);
                    setLoading(false);
                    if (card) {
                      dispatch(showPaymentModal(false));
                    }
                  } else {
                    const { id } = selectedCard;
                    changeDefaultCreditCard({
                      setLoading,
                      setSelectedCard,
                      newCardId: id,
                    });
                  }
                  sendGoogleAnalytics('add_payment_info', {});
                  analyticsEvents.logPaymentInfo(selectedCard.id)
                }}
              >
                <div>Choisir CB</div>
              </Button>
            </>
          )}
        </Modal.Footer>
      </ModalBorder>
    </Modal>
  );
}
