import { DARK_GRAY, UNIT, WHITE } from '../../constants';

const styles = secondaryColor => ({
  starsIcon: {
    color: secondaryColor,
    verticalAlign: 'middle'
  },
  loyaltyButton: {
    backgroundColor: secondaryColor,
    color: WHITE,
    height: '30px',
    borderRadius: UNIT
  },
 disabledProgressbar: {
  backgroundColor: DARK_GRAY,
  color: WHITE,
  height: '30px',
  borderRadius: UNIT
 }
});

export default styles;
