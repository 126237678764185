import React, { useEffect, useRef, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import {
  COLOR_LIGHT_GRAY,
  COLOR_MID_GRAY,
  MODAL_SCROLL_OFFSET,
  UNIT,
} from '../../../../../../constants/configuration';
import { isChatbot } from '../../../../../../utils';
import CloseButton from '../../../../../CloseButton';
import ModalBorder from '../../../../../ModalBorder';
import { getProductData } from './ProductModal.services';
import styles from './ProductModal.styles';
import { useAppSelector } from '../../../../../../redux/hook';
import _ from 'lodash';
import ProductModalBody from './components/ProductModalBody';
import ProductModalFooter from './components/ProductModalFooter';
import { resetHandledItems } from '../../../../../../redux/actions/productsAction'
import  store  from '../../../../../../redux/store';
const { modalOptionContainer } = styles(COLOR_MID_GRAY);

export default function ProductModal({
  product,
  title,
  image,
  price,
  description,
  categories,
  sectionName,
  showModal,
  productKey,
  sectionKey,
  onHideModal,
}) {
  const { categories: storeShopCategories } = useAppSelector(
    (state) => state.shopReducer
  );

  const [nb, setNb] = useState(0);
  const [items, setItems] = useState({});
  const [instruction, setInstruction] = useState('');
  const [productCategories, setProductCategories] = useState([]);
  const [mandatoryCategories, setMandatoryCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [productAdded, setProductAdded] = useState(false);
  const [showOptionScrollButton, setShowOptionScrolButton] = useState(false);
  const [unit, setUnit] = useState(null);
  const [stepOptionIndex, setStepOptionIndex] = useState(0);
  const [itemsCounter, setItemsCounter] = useState({});
  const [modalHeight, setModalHeight] = useState(null);
  const [linkBetweenOptionsAndSubOptions, setLinkBetweenOptionsAndSubOptions] =
    useState(new Map());
  const [shopCategories, setShopCategories] = useState({});

  const prevCategories = useRef(null);

  useEffect(() => {
    if (_.isEqual(prevCategories.current, categories)) {
      return;
    }

    const { unit, productCategories, mandatoryCategories, shopCategories } =
      getProductData(storeShopCategories, product, categories);
    setUnit(unit);
    setNb(unit ? 0 : 1);
    setProductCategories(productCategories);
    setMandatoryCategories(mandatoryCategories);
    setShopCategories(shopCategories);
    store.dispatch(resetHandledItems());
    prevCategories.current = categories;

  }, [storeShopCategories, product, categories]);

  useEffect(() => {
    resetComponent(true);
  }, [storeShopCategories, product]);

  useEffect(() => {
    if (
      !modalHeight &&
      document.getElementById('product-modal-body') &&
      document.getElementById('product-modal-border') &&
      document.getElementById('product-modal-body').offsetHeight >
        document.getElementById('product-modal-border').offsetHeight +
          MODAL_SCROLL_OFFSET
    ) {
      setShowOptionScrolButton(true);
      setModalHeight(
        document.getElementById('product-modal-body').offsetHeight
      );
    }
  }, [modalHeight]);

  const resetComponent = (notHide) => {
    if (!notHide) {
      onHideModal();
    }
    const {
      unit,
      productCategories,
      mandatoryCategories,
      shopCategories: updatedShopCategories,
    } = getProductData(storeShopCategories, product, categories);
    setTimeout(() => {
      setNb(unit ? 0 : 1);
      setItems({});
      setInstruction('');
      setProductCategories(productCategories);
      setMandatoryCategories(mandatoryCategories);
      setLoading(false);
      setProductAdded(false);
      setShowOptionScrolButton(false);
      setUnit(unit);
      setStepOptionIndex(0);
      setItemsCounter({});
      setModalHeight(null);
      setLinkBetweenOptionsAndSubOptions(new Map());
      setShopCategories(updatedShopCategories);
    }, 200);
  };

  return (
    <Modal
      show={showModal}
      onHide={() => resetComponent(false)}
      aria-labelledby='contained-modal-title-vcenter'
      scrollable
      centered
    >
      <ModalBorder
        className={`hide-scroll modal-size-fixed`}
        color={COLOR_LIGHT_GRAY}
        id='product-modal-border'
        onScroll={() => {
          if (
            document.getElementById('product-modal-border').scrollTop <
              document.getElementById('product-modal-title').offsetHeight &&
            document.getElementById('product-modal-body').offsetHeight >
              document.getElementById('product-modal-border').offsetHeight +
                MODAL_SCROLL_OFFSET
          ) {
            setShowOptionScrolButton(true);
          } else {
            setShowOptionScrolButton(false);
          }
        }}
      >
        <div>
          <CloseButton
            closeStyle={{ right: UNIT * 0.5 }}
            onClick={() => {
              resetComponent(false);
            }}
          />
          <Modal.Body
            className='p-3  dark-modal'
            id='product-modal-body'
          >
            <ProductModalBody
              product={product}
              productAdded={productAdded}
              title={title}
              image={image}
              description={description}
              items={items}
              nb={nb}
              unit={unit}
              productCategories={productCategories}
              stepOptionIndex={stepOptionIndex}
              shopCategories={shopCategories}
              modalOptionContainer={modalOptionContainer}
              itemsCounter={itemsCounter}
              mandatoryCategories={mandatoryCategories}
              linkBetweenOptionsAndSubOptions={linkBetweenOptionsAndSubOptions}
              setItems={setItems}
              setShopCategories={setShopCategories}
              setProductCategories={setProductCategories}
              setMandatoryCategories={setMandatoryCategories}
              setLinkBetweenOptionsAndSubOptions={
                setLinkBetweenOptionsAndSubOptions
              }
              setItemsCounter={setItemsCounter}
              instruction={instruction}
              setInstruction={setInstruction}
              setNb={setNb}
              setUnit={setUnit}
              loading={loading}
              price={price}
              setLoading={setLoading}
              sectionName={sectionName}
              sectionKey={sectionKey}
              resetComponent={resetComponent}
              productKey={productKey}
            />
          </Modal.Body>
          {!isChatbot() && (
            <Modal.Footer
              className={`footer-color modal-sticky-footer px-3`}
              style={{
                height: '60px',
              }}
            >
              <ProductModalFooter
                productAdded={productAdded}
                items={items}
                shopCategories={shopCategories}
                price={price}
                nb={nb}
                unit={unit}
                product={product}
                setLoading={setLoading}
                mandatoryCategories={mandatoryCategories}
                productCategories={productCategories}
                productKey={productKey}
                instruction={instruction}
                sectionName={sectionName}
                sectionKey={sectionKey}
                resetComponent={resetComponent}
                stepOptionIndex={stepOptionIndex}
                loading={loading}
                setStepOptionIndex={setStepOptionIndex}
                setShowOptionScrolButton={setShowOptionScrolButton}
                showOptionScrollButton={showOptionScrollButton}
              />
            </Modal.Footer>
          )}
        </div>
      </ModalBorder>
    </Modal>
  );
}
