
import { RESET_HANDLED_ITEMS, MARK_ITEM_AS_HANDLED } from '../actions/types';
const INITIAL_STATE = [];

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case MARK_ITEM_AS_HANDLED:
        return [...state, action.payload];
      case RESET_HANDLED_ITEMS:
        return [];
      default:
        return state;
    }
  };