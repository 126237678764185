import { initBackendV2 } from '../backend-v2'
import apiService from '@dishopsaas/dishop-backend-api-service'
import { applicationError } from '../../utils';
import { getAnalytics } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import { getApp, deleteApp, FirebaseApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

let firebaseModule: FirebaseApp;
let firebaseFleet: FirebaseApp;
let analytics;

export const getFirebaseAuth = () => (firebaseModule ? getAuth(firebaseModule) : undefined);
export const getFirebaseFleet = () => firebaseFleet
export const getFirebaseModule = () => firebaseModule

//----------------------------------------
// FIREBASE INIT
//----------------------------------------
export const initializeFirebase = async () => {
  // GET COMPANY FROM DOMAIN
  const subdomain = window.location.hostname.replace(/^www\./, '')
  let companyId = subdomain.includes('localhost') ? 'dishop-dev' : subdomain.split('.')[0]

  initBackendV2(companyId)

  let obfuscated: string
  try {
    obfuscated = await apiService.firebaseGetCredentials(companyId)
  } catch (err) {
    // companyId is not set
    if (err.code === 404) {
      obfuscated = await apiService.firebaseGetCredentials('dishop-dev')
      initBackendV2('dishop-dev')
      companyId = 'dishop-dev'
    } else throw err
  }
  try {
    const arr = ['tob', 're', 'l', 'rse', 'ce', 'a', 'p', '=', '']
    const firebasCred = JSON[arr[6] + arr[5] + arr[3]](window[arr[5] + arr[0]](obfuscated[arr[1] + arr[6] + arr[2] + arr[5] + arr[4]](/\$[^$]+/, arr[8])[arr[1] + arr[6] + arr[2] + arr[5] + arr[4]](/\$/g, arr[7])))

    const configFleet = firebasCred.fleet
    const configForCompany = firebasCred[companyId]
    const initializeFirebaseApps = () => {
    firebaseModule = initializeApp(configForCompany, 'module');
    firebaseFleet = initializeApp(configFleet, 'fleet');
    analytics = getAnalytics(firebaseModule);
  };

    try {
      initializeFirebaseApps();
    } catch (error) {
      deleteApp(getApp('fleet'));
      deleteApp(getApp('module'));
      initializeFirebaseApps();
    }

  } catch (err) {
    applicationError('Error getting firebase credentials', { err, obfuscated })
  }
}
export { analytics }